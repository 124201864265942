import React, { useState } from "react"
import { getUser } from "../services/auth"
import { updateUser } from "../services/account-services"
import { logout } from "../services/auth"
import { Container, Tabs, Tab } from "react-bootstrap"

const Account = () => {
  const handleSubmit = event => {
    event.preventDefault();
    let userdata = {
        Email: event.target.elements.Email.value,
        FirstName: event.target.elements.FirstName.value,
        Surname: event.target.elements.Surname.value,
        Phone: event.target.elements.Phone.value,
        Location: event.target.elements.Location.value,
        SubscribedToMarketing: event.target.elements.SubscribedToMarketing.checked ? 1 : 0,
        SubscribedToBirdyNewsletter: event.target.elements.SubscribedToBirdyNewsletter.checked ? 1: 0,
        ContactByEmail: event.target.elements.ContactByEmail.checked ? 1 : 0,
        ContactByPhone: event.target.elements.ContactByPhone.checked ? 1 : 0,
        QuickAccess: event.target.elements.QuickAccess.checked ? 1 : 0,
        KnockdownRebuild: event.target.elements.KnockdownRebuild.checked ? 1 : 0,
        SingleStorey: event.target.elements.SingleStorey.checked ? 1 : 0,
        PoolHouse: event.target.elements.PoolHouse.checked ? 1 : 0,
        DoubleStorey: event.target.elements.DoubleStorey.checked ? 1 : 0,
        IHaveKids: event.target.elements.IHaveKids.checked ? 1 : 0,
        HomeOffice: event.target.elements.HomeOffice.checked ? 1 : 0,
        MediaRoom: event.target.elements.MediaRoom.checked ? 1 : 0,
        IHavePets: event.target.elements.IHavePets.checked ? 1 : 0,
        IHaveLand: event.target.elements.IHaveLand.checked ? 1 : 0,
        GrannyFlat: event.target.elements.GrannyFlat.checked ? 1 : 0,
    }
    updateUser(userdata)
  }
  function doLogout() {
    logout()
  }
  const [showAccount, setShowAccount] = useState(true)
  const [showPreferences, setShowPreferences] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  const tabs = ["My Account", "My Preferences"]
  
  var Member = getUser();
  var nameText = '';
  var firstName = Member.FirstName
  if(firstName){
      nameText += firstName.charAt(0);
  }
  var lastName = Member.Surname
  if(lastName){
      nameText += lastName.charAt(0);
  }
  
  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/explore/">Explore</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Accounts
          </li>
        </ol>
      </nav>
      
      <div className="account-avt-wrap">
        <div className="account-avt">
          <a href="/account/my-boards/" className="account-avt-inner">
            <span>{nameText}</span>
          </a>
        </div>
        <p className="account-avt-name d-none d-lg-block">
          {Member.FirstName}
        </p>
      </div>

      <div className="row no-gutters justify-content-center">
        <div className="col-12 col-lg-6 col-xl-5">
          <div className="my-account-header-wrap">
            <a className="my-account-header active">
              <div className="my-account-header-title">My account</div>
            </a>
            <a className="my-account-header" href="/account/my-boards/">
              <div className="my-account-header-title">My boards</div>
            </a>
          </div>
        </div>
      </div>

      <div className="board-page-title">
        <div className="row align-items-center">
          <div className="col-md">
            <h2 className="product-name-title text-uppercase">
              My Account
            </h2>
          </div>
        </div>
      </div>

      <form
        method="post"
        onSubmit={event => {
          handleSubmit(event)
        }}
      >
        <div
          className={`account-setting ${
            currentTab == 0 ? "d-block" : "d-none"
          }`}
        >
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-lg-6 col-xl-5">
              <div className="account-wrap">
                <div className="account-field">
                  <div className="form-group toggle-label">
                    <input
                      defaultValue={Member.FirstName}
                      name="Name"
                      type="text"
                      className="form-control"
                      id="FirstName"
                      placeholder="First Name *"
                      required=""
                    ></input>
                    <label htmlFor="FirstName">First Name *</label>
                  </div>
                  <div className="form-group toggle-label">
                    <input
                      defaultValue={Member.Surname}
                      name="Surname"
                      type="text"
                      className="form-control"
                      id="Surname"
                      placeholder="Last Name *"
                      required=""
                    ></input>
                    <label htmlFor="Surname">Last Name *</label>
                  </div>
                  <div className="form-group toggle-label">
                    <input
                      defaultValue={Member.Email}
                      name="Email"
                      type="text"
                      className="form-control"
                      id="Email"
                      placeholder="Email *"
                      required=""
                    ></input>
                    <label htmlFor="Email">label</label>
                  </div>
                  <div className="form-group toggle-label">
                    <input
                      defaultValue={Member.Phone}
                      name="Phone"
                      type="text"
                      className="form-control"
                      id="Phone"
                      placeholder="Phone *"
                      required=""
                    ></input>
                    <label htmlFor="Phone">Phone *</label>
                  </div>
                  <div className="form-group toggle-label">
                    <input
                      defaultValue={Member.Location}
                      name="Location"
                      type="text"
                      className="form-control"
                      id="Location"
                      placeholder="Location *"
                      required=""
                    ></input>
                    <label htmlFor="Location">Location *</label>
                  </div>
                  <div className="form-group toggle-label has-reset">
                    <input
                      name="Password"
                      type="password"
                      className="form-control"
                      id="Password"
                      placeholder="Password *"
                    ></input>
                    <label htmlFor="Password">Password *</label>
                    <a href="#" className="reset-btn">
                      Reset
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`account-preferences ${
            currentTab == 1 ? "d-block" : "d-none"
          }`}
        >
          <div className="row justify-content-center no-gutters">
            <div className="col-12 col-lg-10 col-xl-8">
              <div className="account-wrap">
                <div className="border-bottom-7px">
                  <h2 className="product-name-title text-left text-lg-center">
                    My Preferences
                  </h2>
                </div>
                <div className="filters has-span d-none">
                  <span>Home preferences</span>
                  <div className="filters-wrap">
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="KnockdownRebuild"
                        id="KnockdownRebuild"
                        type="checkbox"
                        defaultChecked={Member.KnockdownRebuild ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="KnockdownRebuild"
                      >
                        Knockdown Rebuild
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="SingleStorey"
                        id="SingleStorey"
                        type="checkbox"
                        defaultChecked={Member.SingleStorey ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="SingleStorey"
                      >
                        Single Storey
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="PoolHouse"
                        id="PoolHouse"
                        type="checkbox"
                        defaultChecked={Member.PoolHouse ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="PoolHouse"
                      >
                        Pool House
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="DoubleStorey"
                        id="DoubleStorey"
                        type="checkbox"
                        defaultChecked={Member.DoubleStorey ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="DoubleStorey"
                      >
                        Double Storey
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="IHaveKids"
                        id="IHaveKids"
                        type="checkbox"
                        defaultChecked={Member.IHaveKids ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="IHaveKids"
                      >
                        I have kids
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="HomeOffice"
                        id="HomeOffice"
                        type="checkbox"
                        defaultChecked={Member.HomeOffice ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="HomeOffice"
                      >
                        Home Office
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="MediaRoom"
                        id="MediaRoom"
                        type="checkbox"
                        defaultChecked={Member.MediaRoom ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="MediaRoom"
                      >
                        Media Room
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="IHavePets"
                        id="IHavePets"
                        type="checkbox"
                        defaultChecked={Member.IHavePets ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="IHavePets"
                      >
                        I have pets
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="IHaveLand"
                        id="IHaveLand"
                        type="checkbox"
                        defaultChecked={Member.IHaveLand ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="IHaveLand"
                      >
                        I have land
                      </label>
                    </div>
                    <div className="preference custom-control custom-checkbox mb-2">
                      <input
                        className="custom-control-input"
                        name="GrannyFlat"
                        id="GrannyFlat"
                        type="checkbox"
                        defaultChecked={Member.GrannyFlat ? true : false}
                      />
                      <label className="custom-control-label"
                        htmlFor="GrannyFlat"
                      >
                        Granny Flat
                      </label>
                    </div>
                  </div>
                </div>
                <div className="checkbox-group has-span">
                  <span>Marketing Preferences</span>
                  <div className="custom-control custom-checkbox mb-2">
                    <input
                      className="custom-control-input"
                      name="SubscribedToMarketing"
                      id="SubscribedToMarketing"
                      type="checkbox"
                      defaultChecked={Member.SubscribedToMarketing ? true : false}
                    />
                    <label className="custom-control-label"
                      htmlFor="SubscribedToMarketing"
                    >
                      Subscribe to Marketing
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox mb-2">
                    <input
                      type="checkbox"
                      id="SubscribedToBirdyNewsletter"
                      name="SubscribedToBirdyNewsletter"
                      className="custom-control-input"
                      defaultChecked={
                        Member.SubscribedToBirdyNewsletter ? true : false
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="SubscribedToBirdyNewsletter"
                    >
                      Subscribe to Birdy Newsletter
                    </label>
                  </div>
                </div>

                <div className="checkbox-group has-span">
                  <span>Communication Preferences</span>
                  <div className="custom-control custom-checkbox mb-2">
                    <input
                      type="checkbox"
                      name="ContactByEmail"
                      id="ContactByEmail"
                      className="custom-control-input"
                      defaultChecked={Member.ContactByEmail ? true : false}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="ContactByEmail"
                    >
                      Email
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox mb-2">
                    <input
                      type="checkbox"
                      name="ContactByPhone"
                      id="ContactByPhone"
                      className="custom-control-input"
                      defaultChecked={Member.ContactByPhone ? true : false}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="ContactByPhone"
                    >
                      Phone
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters justify-content-center mb-5">
          <div className="col-12 col-lg-6 col-xl-5">
            <div className="account-btn-group">
              <button type="submit" className="btn btn-black btn-lg btn-block">
                SAVE CHANGES
              </button>
              <button
                onClick={doLogout}
                className="btn btn-outline-black  btn-lg btn-block"
              >
                LOG OUT
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default Account
